import React from "react";

import { Route, Routes } from "react-router-dom";

import { PRODUCT, ROOT } from "./Constant";

// import { EMPLOYEE, LOGIN, REGISTER, ROOT, VERIFY_OTP } from "./Constant";
import NotFound from "./NotFound";
import Home from "../pages/Home";
import Products from "../pages/Products";

export const RouterConfig = () => {
  // const EmployeeWithAccess = withDashboard(AddEmployee);
  // const EditEmployeeWithAccess = withDashboard(EditEmployee);

  return (
    <Routes>
      <Route path={ROOT} element={<Home />} />
      <Route path={PRODUCT} element={<Products />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
