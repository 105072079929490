import React, { useEffect } from "react";
import { BrowserRouter } from 'react-router-dom';
import { RouterConfig } from "./navigation/RouterConfig";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <BrowserRouter>
      <RouterConfig />
    </BrowserRouter>
  );
}

export default App;