import React from "react";
import logo from "../../assets/images/logo.png";

import { BsTwitter } from "react-icons/bs";
import { IoMail } from "react-icons/io5";
import { logEvent } from "../../helper/eventHelper";

const Footer = () => {
  return (
    <div className=" text-[white] bg-slate-800 flex ">
      <div className="container mx-auto px-[10px]">
        <hr className="h-[5px] bg-slate-700 border-0 w-[100%]" />

        <div className=" py-[20px]">
          <div className="flex justify-between ">
            <div className="flex gap-x-[20px]">
              {/* <BsTwitter className="text-[#1DA1F2] text-[25px]" /> */}
              <IoMail
                className="text-[#D44638] text-[25px]"
                onClick={() => {
                  logEvent("click", {
                    category: "mail",
                    flow: "mail icon",
                  });
                  window.open("mailto:info@catalystsreachout.com", "_blank");
                }}
              />
            </div>

            <div></div>
          </div>

          <div className="flex justify-between mt-[10px]">
            <div className="flex gap-x-[20px] ">
              <p
                className="text-[14px] capitalize text-gray-300"
                onClick={() => {
                  logEvent("click", {
                    category: "mail",
                    flow: "contact CTA",
                  });
                  window.open("mailto:info@catalystsreachout.com", "_blank");
                }}
              >
                Contact
              </p>
              <p
                className="text-[14px] capitalize text-gray-300"
                onClick={() => {
                  logEvent("click", {
                    category: "feedback",
                    flow: "feedback CTA",
                  });
                  window.open("https://forms.gle/vdmGUkKHpVoVkNuY8", "_blank");
                }}
              >
                Feedback
              </p>
            </div>

            <div>
              <p
                onClick={() => {
                  logEvent("click", {
                    category: "catreachout website",
                    flow: "footer logo",
                  });
                  window.open("https://catalystsreachout.com/", "_blank");
                }}
              >
                <span className="text-gray-400 text-[14px]">Created By </span>
                Catalysts <span className="catalysts-linear"> Reachout</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
