// log the pageview with their URL
export const pageview = (url) => {
  if (typeof window !== 'undefined') {
    window?.gtag('config', "G-5KGVVS78W0", {
      page_path: url,
    })
  }
}

// log specific events happening.
export const event = ({ action, params }) => {
  if (typeof window !== 'undefined') {
    window?.gtag('event', action, params)
  }
}