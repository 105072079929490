import { api } from "./config/axiosConfig";
import { defineCancelApiObject } from "./config/axiosUtils";


export const CommonApi = {
    getCategoryList: async (query, cancel = false) => {
        const response = await api.request({
            url: "curryapp/category_list",
            method: "GET",
            headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
            params: query,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },

    getProductData: async (query, cancel = false) => {
        const response = await api.request({
            url: "curryapp/menu",
            method: "GET",
            headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
            params: query,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    }




}

const cancelApiObject = defineCancelApiObject(CommonApi);
