import React from "react";
import bag from "../../assets/icons/bag.svg";

import user from "../../assets/icons/User.svg";
import search from "../../assets/icons/search.svg";
import { useNavigate } from "react-router-dom";

import { HiInformationCircle } from "react-icons/hi";

import logo from "../../assets/images/logo/logoWhite.svg";

import { Tooltip } from "antd";

const Navbar = ({ focusSearch }: any) => {
  const navigate = useNavigate();

  return (
    <div className="bg-slate-800 py-[15px] shadow-sm shadow-slate-900">
      <div className="container mx-auto flex justify-between ">
        <div className="flex gap-[20px] items-center mx-auto md:mx-0">
          <img src={logo} className=" w-[180px] md:w-[260px]" />
          {/* <p
            className="text-white text-[14px] font-medium cursor-pointer"
            // onClick={() => navigate("/product")}
          >
            Products
          </p>
          <p className="text-white text-[14px] font-medium">What we value</p>
          <p className="text-white text-[14px] font-medium">who we are</p> */}
        </div>

        <div className=" gap-[20px] items-end hidden md:flex">
          <div className="relative" onClick={() => focusSearch()}>
            <img
              src={search}
              alt=""
              className="h-[16px] absolute top-[3px] left-[3px]"
            />
            <input
              className="border-b-[1px] pl-[30px] border-white outline-0 placeholder-white pb-[5px] text-white bg-transparent"
              placeholder="Search Tools"
            />
          </div>

          {/* <img src={bag} alt="" className="h-[20px]" /> */}
          {/* <img src={user} alt="" className="h-[20px] mt-auto" /> */}
          <Tooltip
            placement="bottom"
            title={
              "Need assistance? Reachout to us at info@catalystsreachout.com"
            }
          >
            <HiInformationCircle
              size={25}
              color="white"
              className="cursor-pointer"
            />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
