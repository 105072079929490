import *  as gaEvents from './ga'

export const logEvent = (event, params) => {
    gaEvents.event({
        action: event,
        params: params
    })

    // faEvents.event({
    //     action: 'input',
    //     params: {
    //         category: 'onboarding',
    //         flow: 'otp'
    //     }
    // })

}


export const logScreen = (screenName) => {
    gaEvents.pageview(screenName)
}
