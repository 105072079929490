import React, { useState, useEffect } from "react";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { logEvent } from "../../helper/eventHelper";

const Topbar = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-[var(--secondary)] bg-slate-800 ring-1 ring-slate-900/5  shadow-lg flex border-bottom-[1px]">
      <div className="container flex mx-auto justify-between py-[15px]">
        <div
          className="linear-text mx-auto w-[fit-content] cursor-pointer animate-bounce "
          onClick={() => {
            logEvent("click", {
              category: "instagram",
              flow: "topbar CTA",
            });
            window.open(
              "https://www.instagram.com/catalysts.reachout/",
              "_blank"
            );
          }}
        >
          Follow us on Instagram →
        </div>
      </div>
    </div>
  );
};

export default Topbar;
