import React, { useEffect, Suspense, useRef } from "react";
import Topbar from "../../components/Topbar/Topbar";
import Navbar from "../../components/Navbar/Navbar";
import Banner1 from "../../assets/images/banner1.png";
import item1 from "../../assets/images/item1.png";
import product1 from "../../assets/images/product1.png";
import ingridient from "../../assets/images/ingridient.png";
import Footer from "../../components/Footer";
import { CommonApi } from "../../apis/commonApi";
import { useNavigate } from "react-router-dom";

import videoEditing from "../../assets/images/category/videoEditing-compressed.jpg";
import art from "../../assets/images/category/art-compressed.jpg";
import business from "../../assets/images/category/business-compressed.jpg";
import developerTool from "../../assets/images/category/developerTools-compressed.jpg";
import marketing from "../../assets/images/category/marketing-compressed.jpg";
import trading from "../../assets/images/category/trading-compressed.jpg";

import { VscLinkExternal } from "react-icons/vsc";
import { BiLoaderCircle } from "react-icons/bi";

import { AppData } from "../../data/data";
import { logEvent } from "../../helper/eventHelper";

const SideCategory = [
  {
    name: "Video",
    image: videoEditing,
  },
  {
    name: "Art And Image",
    image: art,
  },
  {
    name: "Developer Tool",
    image: developerTool,
  },
  {
    name: "Business",
    image: business,
  },
  {
    name: "Trading",
    image: trading,
  },
  {
    name: "Marketing",
    image: marketing,
  },
];

const initialCount = 12;

const Home = () => {
  const navigate = useNavigate();

  const [searchText, setSearchText] = React.useState<string>("");

  const [categoryData, setCategoryData] = React.useState<any>([]);
  const [selectedCategory, setSelectedCategory] = React.useState<any>({});

  const [productData, setProductData] = React.useState<any>([]);

  const [productCount, setProductCount] = React.useState<number>(initialCount);

  useEffect(() => {
    // getCategory();
    // getProduct();
    const array = [...AppData];
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }

    setProductData(array);

    const cat: any = [];
    for (var i = 0; i < array.length; i++) {
      if (array[i]["Category"] && !cat.includes(array[i]["Category"])) {
        cat.push(array[i]["Category"]);
      }
    }

    setCategoryData(cat);
  }, []);

  const inputref = useRef<any>(null);
  const productRef = useRef<any>(null);

  const focusSearch = () => {
    if (inputref.current) {
      inputref.current.scrollIntoView({ block: "center", behavior: "smooth" });
      setTimeout(() => {
        inputref.current.focus();
      }, 1000);
    }
  };

  const handleSearch = (text: string) => {
    setSearchText(text);
    if (text.length < 3 && text.length > 0) {
      return;
    }
    const filteredData = AppData.filter((item: any) => {
      return item.Category.toLowerCase().includes(text.toLowerCase());
    });

    setProductData(filteredData);
    setProductCount(initialCount);
    logEvent("input", {
      category: "search",
      flow: "search bar",
      input: text,
    });
  };

  const scrollToProducts = () => {
    productRef.current.scrollIntoView({ block: "start", behavior: "smooth" });
  };

  return (
    <div className="bg-slate-800 scroll-smooth">
      <Topbar />
      <Navbar
        focusSearch={() => {
          focusSearch();
          logEvent("click", {
            category: "search",
            flow: "navbar",
          });
        }}
      />

      {/* Section 1 */}
      <div className="container  mx-auto pt-[50px] px-[10px]">
        <div className="bg-[var(--secondary)] bg-slate-800 shadow-inner   shadow-slate-900 md:p-[60px] p-[30px] flex rounded-[20px]">
          <div className="content flex-[1] flex flex-col items-center my-auto">
            <h1 className="font-semibold text-center  text-[30px] md:text-[50px] text-white">
              The newest and most
            </h1>
            <h6 className=" md:text-[50px] text-[30px] text-center text-transparent font-semibold bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">
              Popular AI Tools In One Place
            </h6>
            <p className="mt-[10px] text-[#636363] text-center text-gray-300 md:w-[50%] w-[90%]">
              Discover a collection of the latest and highly sought-after AI
              tools, all conveniently curated in one place. Streamline your
              workflow and harness the power of cutting-edge technology
              effortlessly.
            </p>
            <button
              className=" border-0 mt-[45px] outline-0 mt-[20px] rounded-[100px] bg-gradient-to-r from-purple-400 to-pink-600 py-[13px] px-[30px] text-white"
              onClick={() => {
                focusSearch();
                logEvent("click", {
                  category: "search",
                  flow: "explore CTA",
                });
              }}
            >
              Explore
            </button>
          </div>
        </div>
      </div>

      {/* Section 2 */}
      <div className="container mx-auto mt-[120px] px-[10px]">
        <div className="grid md:grid-cols-6 grid-cols-2 md:gap-[20px] gap-y-[30px]">
          {[...SideCategory].map((item: any, i) => (
            <div
              className="flex items-center justify-center flex-col cursor-pointer"
              onClick={() => {
                handleSearch(item?.name);
                scrollToProducts();
                logEvent("click", {
                  category: "filter",
                  flow: "category",
                  name: item?.name,
                });
              }}
              key={i}
            >
              <div className="rounded-[50%] overflow-hidden flex items-center bg-slate-800 mb-[-40px] z-[200] p-[10px] bg-[#fff] relative ">
                <div className="relative h-[100px] w-[100px] md:h-[120px] md:w-[120px] overflow-hidden flex flex-col">
                  <img
                    src={item?.image}
                    alt=""
                    className="object-cover w-[100%] h-[100%] rounded-[50%]"
                  />
                </div>
              </div>
              <div className="pb-[20px] max-w-[200px] w-[180px] bg-slate-700 px-[10px] rounded-[20px] pt-[60px] bg-[var(--secondary)]">
                <p className="text-center text-gray-200 whitespace-nowrap">
                  {item?.name}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Section 3 */}
      <div className="container mx-auto   bg-cover rounded-[20px] overflow-hidden flex justify-center items-center py-[10px] mt-[90px] flex-col">
        <div className="mb-[60px]">
          <p className="text-gray-200">
            {AppData?.length} <span className="text-pink-600"> | </span> Total
            tools
          </p>
        </div>
        <div className="content w-[100%]  flex items-center flex-col">
          <input
            ref={inputref}
            value={searchText}
            onChange={(e) => handleSearch(e.target.value)}
            className="text-white placeholder:text-gray-400 border-[1px] border-slate-300 py-[20px] px-[25px] outline-[0px] bg-transparent rounded-[15px] w-[75%] shadow-inner shadow-slate-800 "
            placeholder="Search for a category (ex : video)"
          />
        </div>
      </div>

      {/* Section 4 */}

      <div
        ref={productRef}
        className="container px-[10px] mx-auto flex flex-col items-center justify-center mt-[120px] mb-[150px]"
      >
        <div className="grid md:grid-cols-3 grid-cols-1 gap-[45px] px-[30px] md:px-[0px]">
          {[...productData].map((item, index) => {
            if (index < productCount) {
              return (
                <div
                  key={index}
                  className="bg-[#fff] bg-slate-900 col-span-1 border-[1px] border-fuchsia-800 flex flex-col items-center justify-center   shadow-slate-900 rounded-[20px] p-[20px] hover:shadow-md hover:bg-slate-900 cursor-pointer hover:shadow-fuchsia-900"
                >
                  <div className="rounded-[10px] w-[100%] overflow-hidden  flex h-[200px]">
                    <img
                      src={`/aiToolsImage/${item["Link"]?.substring(8)}.png`}
                      alt=""
                      className="object-cover object-top w-[100%]"
                    />
                    {/* <Suspense fallback={<div>Loading...</div>}>
                      <Iframe
                        url={item["Link"]}
                        // allowFullScreen
                        loading="lazy"
                        scrolling="no"
                        className="w-[100%]"
                        onLoad={() => {
                          console.log("loaded");
                        }}
                      />
                    </Suspense> */}
                  </div>
                  <h3 className="text-[18px] mt-[15px] font-semibold text-gray-100">
                    {item["Tool"]} ({" "}
                    <span className="text-pink-500 text-[14px] font-500">
                      {item["Category"]}
                    </span>{" "}
                    )
                  </h3>
                  <p className="mt-[10px] text-[#636363] text-[14px] mb-[10px] text-gray-300">
                    {item["Description"]?.substring(0, 100)}...
                  </p>
                  <div className="mb-[auto] items-start flex flex-wrap  gap-[10px] mb-[10px]">
                    {item["Keywords"]?.length > 0 &&
                      item["Keywords"]?.map((itemKey: any, index: any) => {
                        if (index < 10)
                          return (
                            <p
                              key={"cat" + index}
                              className="text-[10px] text-slate-300 border-[1px] border-purple-500 py-[5px] px-[15px] rounded-[10px]"
                            >
                              {itemKey}
                            </p>
                          );
                      })}
                  </div>
                  <button
                    className=" border-0 outline-0 mt-[20px] rounded-[100px] bg-gradient-to-r from-purple-500 to-pink-600 py-[8px] px-[20px] text-white font-[400] text-[14px] min-w-[200px] flex items-center  justify-center gap-[10px]"
                    onClick={() => {
                      window.open(item["Link"]);
                      logEvent("click", {
                        category: "tool",
                        flow: "card",
                        name: item["Tool"],
                        link: item["Link"],
                      });
                    }}
                  >
                    Visit <VscLinkExternal color={"#fff"} />
                  </button>
                </div>
              );
            }
          })}
        </div>

        {productData.length > productCount && (
          <button
            className=" border-0 mt-[90px] outline-0 mt-[20px] rounded-[100px] bg-gradient-to-r from-purple-400 to-pink-600 py-[13px] px-[30px] text-white flex gap-[10px] items-center justify-center"
            onClick={() => {
              setProductCount(productCount + 6);
              logEvent("click", {
                category: "load more",
                flow: "load more CTA",
              });
            }}
          >
            Load More <BiLoaderCircle color={"#fff"} />
          </button>
        )}
      </div>

      {/* <div className="container mx-auto flex flex-col items-cetner justify-center mt-[120px]">
        <h2 className="text-[40px] font-semibold text-center ">
          Curry Cook’s Menu
        </h2>
        <div className="mt-[30px]">
          <div className="grid grid-cols-4 gap-[30px]">
            <div className="p-[20px] flex flex-col rounded-[20px] bg-[var(--secondary)]">
              <h2 className="text-[24px] font-semibold ">Category</h2>
              <hr className="h-[2px] bg-[var(--neutral1)] my-[15px]" />

              {categoryData.map((item: any, index: number) => {
                if (index < 5)
                  return (
                    <>
                      <h6
                        className={
                          " text-[18px] font-[500] cursor-pointer " +
                          (selectedCategory?.category_id == item?.category_id
                            ? "text-[var(--primary)]"
                            : "")
                        }
                        onClick={() => setSelectedCategory(item)}
                      >
                        {item.category_name}
                      </h6>
                      <hr className="h-[2px] bg-[#ECEEF0] my-[15px]" />
                    </>
                  );
              })}

              <button
                className="border-0 outline-0 w-[100%] rounded-[100px] bg-[var(--primary)] py-[13px] px-[30px] text-white mt-[auto] font-600 "
                onClick={() => navigate("product")}
              >
                View all
              </button>
            </div>

            <div className="col-span-3 flex flex-col">
              <div className="flex ml-auto gap-[30px]">
                <div className="bg-[#fff] cursor-pointer border-[1px] border-[#D3D4D6] h-[50px] shadow-md flex items-center justify-center w-[50px] rounded-[50%]">
                  <HiChevronLeft size={40} />
                </div>
                <div className="bg-[var(--primary)] cursor-pointer h-[50px] shadow-md flex items-center justify-center w-[50px] rounded-[50%]">
                  <HiChevronRight color={"white"} size={40} />
                </div>
              </div>

              <div className="grid grid-cols-3  gap-[30px] mt-[auto]">
                {[
                  ...productData?.filter(
                    (item: any) =>
                      item.category_id == selectedCategory?.category_id
                  ),
                ].map((item, i) => {
                  if (i < 3)
                    return (
                      <div className="bg-[#fff] col-span-1 border-[1px] border-[#D3D4D6] flex flex-col items-center justify-center shadow-md rounded-[20px] p-[20px]">
                        <div className="rounded-[10px] w-[100%] overflow-hidden  flex h-[162px]">
                          <img
                            src={item?.product_photo}
                            alt=""
                            className="object-cover w-[100%]"
                          />
                        </div>
                        <h3 className="text-[20px] mt-[10px] font-semibold">
                          {item?.product_name}
                        </h3>
                        <p className="mt-[5px] text-[#636363] text-[14px] mb-[10px]">
                          {item?.product_desc}
                        </p>
                        <div className="mt-[auto] flex items-end gap-[10px] mb-[10px]">
                          <p className="text-[14px] font-[500] relative text-[#636363]">
                            ${item?.product_price}
                            <div className="h-[1px] bg-[#636363] absolute w-[100%] top-[50%]"></div>
                          </p>
                          <p className="text-[20px] text-[var(--primary)]">
                            $
                            {Number(
                              item?.product_price - item?.discount
                            )?.toFixed(2)}
                          </p>
                        </div>
                        <button
                          className="border-0 outline-0  rounded-[50px] bg-[var(--primary)] py-[10px] px-[30px] text-white font-600 w-[100%] mt-[10px]"
                          onClick={() => {
                            navigate("product?cartAdd=" + item?.product_id);
                          }}
                        >
                          Add to cart
                        </button>
                      </div>
                    );
                })}
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* Section 5 */}

      {/* <div className="mt-[120px] flex ">
        <div className="bg-homeImg2  bg-cover bg-center bg-no-repeat  object-contain bg-norepeat flex-[0.5]"></div>
        <div className="flex-[0.5] flinex flex-col justify-center items-center bg-[var(--secondary)] py-[30px]">
          <div className="mx-auto w-[fit-content]">
            <h2 className="text-[40px] font-[600] text-center">
              Essential Spices
            </h2>
            <div className="mt-[30px] w-[500px] flex flex-col gap-[20px]">
              {[...EssentialSpecies].map((item: any, i) => (
                <div className="p-[10px] bg-[#fff] rounded-[10px] flex gap-[15px]">
                  <div className="rounded-[10px] overflow-hidden min-w-[80px] h-[80px] flex">
                    <img src={item?.image} alt="" className=" w-[100%]" />
                  </div>

                  <div>
                    <h2 className="text-[20px] font-semibold">{item?.title}</h2>
                    <p className="mt-[5px] text-[#636363] text-[12px]">
                      {item?.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div> */}

      {/* Section 6 */}

      {/* <div className="mt-[120px] container mx-auto">
        <h2 className="text-[40px] font-semibold text-center ">Recent Blogs</h2>
        <div className="mt-[30px] flex flex-col">
          <div className="grid grid-cols-3 gap-[30px]">
            {[...Array(3)].map((_, i) => (
              <div className="bg-[#fff] max-w-[400px] mx-auto col-span-1 border-[1px] border-[#D3D4D6] flex flex-col  justify-center shadow-md rounded-[20px] p-[20px]">
                <div className="rounded-[10px] overflow-hidden flex h-[162px] w-[100%] ">
                  <img
                    src={product1}
                    alt=""
                    className="object-cover w-[100%]"
                  />
                </div>
                <p className="text-[12px] font-[500] mt-[10px] text-[#636363] text-start">
                  23 June, 2023
                </p>
                <h2 className="mt-[10px] text-[20px] font-[600]">
                  Et minima ea non occaecati quaerat.
                </h2>
                <p className="text-[14px] text-[#636363] mt-[5px]">
                  Numquam sint ut inventore sed. Enim fugit eos expedita dolor
                  id. Inventore itaque perspiciatis. Minus ea aliquam. Iure
                  optio quo.
                </p>
              </div>
            ))}
          </div>

          <button className="border-0 outline-0 mx-auto mt-[30px] rounded-[100px] bg-[var(--primary)] py-[13px] px-[30px] text-white font-600 ">
            Browse more
          </button>
        </div>
      </div> */}

      {/* Section 7 */}

      {/* <div className="mt-[120px] container mx-auto flex rounded-[20px] overflow-hidden">
        <div className="w-[50%] bg-[var(--secondary)] p-[50px]">
          <h2 className="text-[40px] font-semibold">Get in touch</h2>

          <div className="mt-[20px] flex flex-col gap-[20px]">
            <div className="flex flex-col ">
              <label className="text-[14px] font-[500]">Your Name*</label>
              <input
                className="h-[50px] rounded-[20px]  mt-[5px] px-[20px] py-[13px]"
                placeholder="Your Name"
              />
            </div>

            <div className="flex flex-col ">
              <label className="text-[14px] font-[500]">Email*</label>
              <input
                className="h-[50px] rounded-[20px]  mt-[5px] px-[20px] py-[13px]"
                placeholder="Your Email Address"
              />
            </div>

            <div className="flex flex-col ">
              <label className="text-[14px] font-[500]">Mobile no*</label>
              <input
                className="h-[50px] rounded-[20px]  mt-[5px] px-[20px] py-[13px]"
                placeholder="Your Email Address"
              />
            </div>

            <div className="flex flex-col">
              <label className="text-[14px] font-[500]">Message</label>
              <textarea
                className="h-[250px] rounded-[20px]  mt-[5px] px-[20px] py-[13px]"
                placeholder="Your Message"
              />
            </div>

            <button className="border-0 outline-0 w-[fit-content] rounded-[100px] bg-[var(--primary)] py-[13px] px-[30px] text-white font-600 ">
              Submit
            </button>
          </div>
        </div>
        <div className="w-[50%] bg-homeImg3 bg-contain"></div>
      </div> */}

      {/* Section 8 */}

      {/* <div className="mt-[120px]">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3172.3325395304414!2d-122.01116148467422!3d37.33463524513264!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb59127ce078f%3A0x18e1c3ce7becf1b!2sApple%20Park!5e0!3m2!1sen!2sin!4v1637309850935!5m2!1sen!2sin"
          width={"100%"}
          height="350"
          style={{ border: 0 }}
          allowFullScreen={true}
          loading="lazy"
        ></iframe>
      </div> */}

      {/* Section 9 */}

      <Footer />
    </div>
  );
};

export default Home;
